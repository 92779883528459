import React from "react"
import PropTypes from "prop-types"

const Css3 = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 361"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <path
      d="M127.844 360.088L23.662 331.166.445 70.766h255.11l-23.241 260.36-104.47 28.962z"
      fill="#264DE4"
    />
    <path
      d="M212.417 314.547l19.86-222.49H128V337.95l84.417-23.403z"
      fill="#2965F1"
    />
    <path
      d="M53.669 188.636l2.862 31.937H128v-31.937H53.669zM47.917 123.995l2.903 31.937H128v-31.937H47.917zM128 271.58l-.14.037-35.568-9.604-2.274-25.471h-32.06l4.474 50.146 65.421 18.16.147-.04V271.58z"
      fill="#EBEBEB"
    />
    <path
      d="M60.484 0h38.68v16.176H76.66v16.176h22.506v16.175H60.484V0zM106.901 0h38.681v14.066h-22.505v2.813h22.505v32.352h-38.68V34.46h22.505v-2.813H106.9V0zM153.319 0H192v14.066h-22.505v2.813H192v32.352h-38.681V34.46h22.505v-2.813H153.32V0z"
      fill={props.light ? "#161614" : "#FFFFFF"}
    />
    <path
      d="M202.127 188.636l5.765-64.641H127.89v31.937h45.002l-2.906 32.704H127.89v31.937h39.327l-3.708 41.42-35.62 9.614v33.226l65.473-18.145.48-5.396 7.506-84.08.779-8.576z"
      fill="#FFF"
    />
  </svg>
)

Css3.propTypes = {
  light: PropTypes.number,
}

Css3.defaultProps = {
  light: 0,
}
export default Css3
