import React from "react"

const Contentful = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 289"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <title>Contentful</title>
    <path
      d="M87.333 200.777c-15.306-14.406-24.309-34.213-24.309-56.722s9.003-42.316 23.409-56.722c12.605-12.604 12.605-32.412 0-45.017-12.605-12.605-32.412-12.605-45.017 0C16.206 68.426 0 104.44 0 144.055s16.206 75.629 42.316 101.739c12.605 12.605 32.413 12.605 45.017 0 11.705-12.605 11.705-32.413 0-45.017z"
      fill="#FAE501"
    />
    <path
      d="M87.333 87.333c14.406-15.306 34.213-24.309 56.722-24.309s42.316 9.003 56.722 23.409c12.604 12.605 32.412 12.605 45.017 0 12.605-12.605 12.605-32.412 0-45.017C219.684 16.206 183.67 0 144.055 0S68.426 16.206 42.316 42.316c-12.605 12.605-12.605 32.413 0 45.017 12.605 11.705 32.413 11.705 45.017 0z"
      fill="#4FB5E1"
    />
    <path
      d="M200.777 200.777c-14.406 15.305-34.213 24.309-56.722 24.309s-42.316-9.004-56.722-23.41c-12.604-12.604-32.412-12.604-45.017 0-12.605 12.606-12.605 32.413 0 45.018 26.11 25.21 62.124 41.416 101.739 41.416s75.629-16.206 101.739-42.316c12.605-12.605 12.605-32.413 0-45.017-12.605-11.705-32.413-11.705-45.017 0z"
      fill="#F05751"
    />
    <circle fill="#0681B6" cx={64.825} cy={64.825} r={31.512} />
    <circle fill="#CD4739" cx={64.825} cy={223.285} r={31.512} />
  </svg>
)

export default Contentful
