import React from "react"

const Vue = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 221"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <path
      d="M204.8 0H256L128 220.8 0 0h97.92L128 51.2 157.44 0h47.36z"
      fill="#41B883"
    />
    <path d="M0 0l128 220.8L256 0h-51.2L128 132.48 50.56 0H0z" fill="#41B883" />
    <path
      d="M50.56 0L128 133.12 204.8 0h-47.36L128 51.2 97.92 0H50.56z"
      fill="#35495E"
    />
  </svg>
)

export default Vue
