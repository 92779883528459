import React from "react"

const Gulp = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 566"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <path
      d="M197.28 548.749l5.427-90.43 14.985-25.58s-34.106 13.952-91.205 13.952c-57.1 0-90.689-13.694-90.689-13.694l16.794 27.904 5.941 87.848c0 9.418 31.059 17.052 69.374 17.052 38.313 0 69.373-7.634 69.373-17.052M180.161 126.454l11.391-45.025 63.081-66.376L238.007.892l-66.784 70.707-13.226 53.793a886.14 886.14 0 00-29.873-.497c-70.336 0-127.355 8.016-127.355 17.902 0 9.887 57.019 17.902 127.355 17.902 70.335 0 127.353-8.015 127.353-17.902 0-7.28-30.924-13.546-75.316-16.343"
      fill="#D34A47"
    />
    <path
      d="M173.535 151.669s-2.467.553-9.724.584c-7.259.03-12.413-.047-14.466-1.917-.552-.502-.854-1.499-.851-2.174.006-1.285.918-2.042 2.08-2.516l1.099 1.724c-.478.165-.751.36-.752.568-.004.976 7.422 1.644 13.102 1.572 5.681-.07 12.545-.538 12.551-1.5 0-.291-.519-.565-1.422-.781l1.094-1.712c1.472.457 2.757 1.34 2.754 2.868-.014 2.598-3.32 2.993-5.465 3.284"
      fill="#FFF"
    />
    <path
      d="M254.509 15.184c1.586-1.832-.821-6.518-5.376-10.465C244.576.774 239.596-.94 238.007.892c-1.587 1.832.821 6.518 5.378 10.463 4.555 3.946 9.537 5.662 11.124 3.829M194.246 327.865c-1.495-14.384 31.462-35.216 23.004-35.883-18.208 1.041-27.27 21.338-36.431 42.596-3.357 7.791-14.844 41.013-22.378 36.923-7.532-4.088 9.765-31.407 14.613-47.631-5.594 4.102-26.109 20.041-31.396 5.114-8.5 7.219-26.663 11.13-24.614-7.833-4.522 8.01-14.671 19.193-26.853 14.546-15.998-6.101 9.27-57.062 15.553-54.491 6.284 2.57-1.268 14.179-3.246 18.687-4.35 9.921-9.375 22.393-6.018 24.946 5.751 4.374 21.401-16.547 21.749-17.007 2.927-3.867 11.266-29.166 17.987-26.261 6.721 2.905-16.764 36.308-7.983 42.937 1.771 1.338 9.017-.825 13.425-5.594 2.852-3.085 1.871-9.918 11.378-32.59 9.507-22.671 17.98-50.905 24.5-48.754 6.52 2.15 1.146 16.641-1.193 22.043-10.976 25.354-29.956 67.241-21.005 64.07 8.951-3.17 13.612-3.356 22.377-13.613 8.765-10.257 8.361-27.152 14.203-26.793 5.844.36 4.863 5.777 3.446 9.902 5.75-6.58 27.256-20.438 32.448-6.713 6.15 16.249-30.769 39.72-21.564 38.523 8.975-1.167 23.515-10.376 29.723-18.749l17.088-153.626s-17.195 14.23-126.589 14.23c-109.395 0-124.811-13.934-124.811-13.934l14.548 140.224c7.722-21.947 26.059-66.339 54.963-64.849 13.148.678 30.7 25.995 15.665 26.853-6.364.362-7.01-12.637-14.546-14.546-5.438-1.378-13.009 3.043-17.902 7.833-9.758 9.55-30.745 47.229-27.972 66.014 3.533 23.949 33.048-8.322 38.042-17.903 3.517-6.749 5.893-26.745 13.585-24.647 7.693 2.098-.671 22.734-6.247 40.979-6.264 20.487-9.557 42.098-17.408 39.612-7.852-2.484 4.938-28.932 4.475-33.566-7.38 6.809-20.976 24.047-38.37 15.515l9.035 87.078s24.013 18.973 96.35 18.973c72.337 0 97.239-18.677 97.239-18.677l11.219-100.857c-9.179 8.938-38.229 24.81-40.089 6.919"
      fill="#D34A47"
    />
  </svg>
)

export default Gulp
