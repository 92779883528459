import React from "react"

const Grunt = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 344"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <path
      d="M17.297 83.373s5.875 22.658 6.713 30.212c.84 7.552-15.105 24.336-15.105 32.728 0 8.393 3.352 23.498 21.817 31.052 18.465 7.553 20.98 26.853 20.98 38.605 0 11.748-6.713 34.404-6.713 34.404S40.795 306.6 63.451 316.67c22.656 10.069 17.623 10.069 22.656 10.069 5.034 0 41.96 15.11 63.778 9.234 21.82-5.874 27.69-12.587 27.69-12.587s21.821 1.677 28.534-17.627c6.716-19.3 10.91-59.58 8.391-78.043-2.518-18.465-5.873-42.797 9.23-51.19 15.11-8.393 31.052-18.46 28.534-26.854-2.518-8.393-15.941-20.142-15.941-20.142s-3.358-20.978-.842-26.014c2.516-5.035 17.623-17.626 9.23-26.855-8.387-9.23-23.497-9.23-23.497-9.23l-19.3 1.676s-32.728-29.37-75.527-27.692C83.59 43.093 56.734 69.107 56.734 69.107s-14.265-5.035-26.854 0c-12.589 5.037-15.099-.001-12.583 14.266z"
      fill="#FBA919"
    />
    <path
      d="M93.978 208.32s-16.619 25.41-28.809 43.443c-1.886 2.79 12.19 2.983 12.19 2.983l16.619-46.427zM101.89 155.637s-5.01 12.498-7.754 19.123c-2.978 7.203-14.336 10.786-14.336 10.786s5.887-6.175 5.887-21.818c0-6.562 8.473-9.172 8.473-9.172l7.73 1.08z"
      fill="#E78724"
    />
    <path
      d="M96.83 162.703s-10.264 12.218-29.32 5.375c-19.061-6.84-21.015-31.278-21.015-38.12 0-6.842 36.164 6.842 41.053 9.775 4.888 2.933 14.168 14.66 9.281 22.97zM24.843 32.153s17.593-3.424 29.32 5.863c11.733 9.285 15.15 16.617 15.15 16.617l-19.769 15.67s-9.552-4.428-12.484-17.136c-2.932-12.707-.977-18.57-12.217-21.014z"
      fill="#E78724"
    />
    <path
      d="M91.698 127.413c-7.574-10.752-20.057-15.442-30.024-16.219-16.994-1.329-25.062 4.679-25.062 4.679s27.832 1.178 35.854 11.714c14.934 19.61 36.721 21.203 40.205 16.896-4.029.201-8.46-1.795-13.204-6.28-2.963-2.806-5.365-7.377-7.769-10.79zM44.714 165.431s-30.88-10.616-25.011-21.365c5.863-10.753 1.196-16.612 1.196-16.612S3.656 140.326 8.049 154.01c2.701 8.398 7.231 14.479 32.107 26.137 11.932 22.163 2.464 62.055 3.031 62.242.945.314 16.753-7.813 15.674-45.135-.33-11.46-1.246-27.106-14.147-31.822zM85.18 51.276c-12.948 6.578-30.687 15.739-30.687 15.739l6.982 3.493c-.667 1.079-.988 1.723-.988 1.723l7.166 3.258s17.493 12.672 28.21 20.82C92.29 85.303 81.8 68.016 81.8 68.016s-2.972-7.943 3.38-16.741zM128.196 319.42c-39.8 0-53.28-20.204-53.28-20.204l6.22 15.146c2.35 6.552 27.43 26.942 47 26.942"
      fill="#E78724"
    />
    <path
      d="M79.277 285.564s3.662 22.378 18.324 22.378c12.459 0 16.13-18.903 16.13-22.81l-34.454.432zM54.878 251.814c1.303-3.586 5.864-18.084 7.329-31.278 1.756-15.803 1.116-38.915 1.116-38.915s-3.912 15.15-7.333 17.598c-3.419 2.442-9.774 19.057-9.774 19.057s-3.404 24.736-5.024 33.05c4.484.897 8.258-1.744 13.686.488zM19.472 153.766s8.306-2.935 10.752-21.015c2.443-18.083-2.934-33.722-2.934-33.722s.488 24.924-2.443 29.813c-2.934 4.885-5.375 6.352-8.798 12.707-3.423 6.356 1.465 10.261 3.423 12.217z"
      fill="#E78724"
    />
    <path
      d="M29.39 36.43l-1.696-1.074c-5 .169 1.696 1.074 1.696 1.074z"
      fill="#FDC168"
    />
    <path
      d="M91.94 45.922s-7.816-1.302-11.728-7.166C76.3 32.891 65.225 21.16 47.568 21.03c-17.656-.132-35.124 4.693-35.124 4.693l15.248 9.635c1.613-.053 4.433-.031 9.096.125 19.137.636 22.083 19.564 22.083 19.564l67.442-9.287"
      fill="#FBA919"
    />
    <path
      d="M98.459 150.469s-9.124 9.123-23.136 6.189c-14.011-2.931-22.484-9.122-23.135-19.875-.651-10.752 2.935-14.987 2.935-14.987s15.637 4.89 23.135 11.407c7.494 6.515 13.032 16.941 20.2 17.266z"
      fill="#FFF"
    />
    <path
      d="M87.417 147.21s-7.913 3.614-16.757.67c-11.577-3.862-12.348-15.902-12.348-15.902s-7.103 20.843 12.81 24.855c15.971 3.217 25.417-6.038 25.417-6.038l-9.122-3.586z"
      fill="#C9C9C9"
    />
    <path
      d="M93.04 41.5c-.851-17.874 13.802-32.11 18.88-32.717.28 3.029-.284 14.844 4.792 18.477C118.19 17.61 123.187 6.97 142.92 0c-4.226 11.209-.565 20.598 1.18 24.95 13.474-12.23 22.553-10.21 22.553-10.21s-9.363 16.156-5.7 26.757c-27.05-2.121-67.781 2.874-67.913.004zM35.694 68.728c-6.455-5.355-1.644-10.324-2.154-16.408-.277-3.237-.533-6.291-1.6-7.976C26 34.966 11.989 32.12 11.849 32.09l-6.576-1.288 4.353-5.092c.564-.66 18.44-17.717 46.105-8.785 19.352 6.241 33.678 27.405 34.211 28.26l-9.703 4.563c-.114-.18-11.44-20.074-28.042-25.427-13.42-4.33-25.79-.21-31.087 2.49 5.632 2.248 12.995 6.343 17.432 13.348 2.093 3.309 2.447 7.474 2.788 11.508.45 5.34.87 10.381 4.8 13.646l-10.436 3.415zM18.456 146.893s8.131-10.084 10.54-29.704c.946-7.729-.735-22.972-3.424-28.836.489 14.417-3.176 28.836-3.176 28.836s-2.471 26.771-3.94 29.704z"
      fill="#463014"
    />
    <path
      d="M61.334 58.544c-.005-.021-2.61-11.405-9.832-17.152-10.188-8.104-22.627-3.793-22.747-3.755l-1.806-5.58c.602-.195 14.82-4.675 27.923 4.536 13.182 9.263 14.764 17.66 14.901 18.587l-8.44 3.364zM43.995 139.661s-4.266-6.107-3.499-12.823c.382-3.317 1.92-6.722 4.663-7.93 5.1-2.24 7.36 3.54 7.36 3.54s-7.177 1.771-8.524 17.213z"
      fill="#463014"
    />
    <path
      d="M90.836 148.97c.178.332.27.519.27.519s-13.307 9.986-26.943-.148c-7.3-5.43-6.61-18.859-4.374-24.554 5.816 1.318 10.748 3.693 14.87 6.467-5.21-4.65-9.77-8.073-9.758-8.152l-5.922-1.676-11.51-3.947c-.147.878-4.345 20.556 7.773 33.964 5.37 5.946 30.55 19.26 42.506-3.506l-6.912 1.032z"
      fill="#463014"
    />
    <path
      d="M89.59 150.433c7.491 11.402 26.393 4.886 26.068-7.168 0 0-12.63 6.97-23.789-5.864-6.515-7.492-27.39-28.151-55.7-21.286 19.55 4.3 45.433 22.16 53.42 34.318z"
      fill="#463014"
    />
    <path
      d="M33.525 182.613c1.802 1.041 8.158 7.624 8.162 17.468 0 4.937-.491 29.737-2.935 44.888 3.675 2.388 6.235 2.896 9.895 5.33 1.832-17.547 3.146-42.979 2.534-53.246-.7-11.703-6.967-19.557-12.778-22.906-1.82-1.047-3.721-2.076-5.636-3.111-9.953-5.378-20.246-10.942-20.246-20.441 0-7.432 2.89-9.756 5.948-12.22l.33-.267c2.03-1.64 4.326-3.5 5.687-6.553 3.713-8.348 4.32-29.615 0-35.013-2.154-2.69-7.924-11.736-6.265-17.815.632-2.32 2.093-4.04 4.472-5.258 2.517-1.29 5.918-1.943 10.104-1.943 8.222 0 17.342 2.503 22.047 3.993 16.813 5.329 37.101 20.693 42.916 25.275.167.128.265.203.283.215l3.721 2.588-2.327-3.894c-.144-.243-14.883-24.479-41.238-33.365-5.127-1.729-15.2-4.63-25.1-4.63-5.893 0-10.895 1.03-14.854 3.057-4.827 2.469-8.095 6.408-9.453 11.388-3.328 12.207 7.511 25.798 7.88 26.233 1.264 2.256 1.8 18.643-1.12 25.201-.4.906-1.502 1.795-2.895 2.92-.437.353-.925.72-1.442 1.112C7.096 134.728.868 139.425.868 150.252c0 14.814 15.014 22.846 27.08 29.304 1.98 1.065 3.869 2.073 5.577 3.057z"
      fill="#463014"
    />
    <path
      d="M35.694 68.728c-6.455-5.355-1.644-10.324-2.154-16.408-.277-3.237-.533-6.291-1.6-7.976C26 34.966 11.989 32.12 11.849 32.09l-6.576-1.288 4.353-5.092c.564-.66 18.44-17.717 46.105-8.785 19.352 6.241 33.678 27.405 34.211 28.26l-9.703 4.563c-.114-.18-11.44-20.074-28.042-25.427-13.42-4.33-25.79-.21-31.087 2.49 5.632 2.248 12.995 6.343 17.432 13.348 2.093 3.309 2.447 7.474 2.788 11.508.45 5.34.87 10.381 4.8 13.646l-10.436 3.415z"
      fill="#463014"
    />
    <path
      d="M35.484 69.825l-.417-.346c-4.786-3.972-3.937-7.884-3.115-11.67.374-1.747.768-3.553.608-5.406-.261-3.115-.512-6.054-1.45-7.537-5.655-8.93-19.323-11.79-19.458-11.818L3.44 31.437l5.444-6.365c.1-.12 11.578-11.706 31.017-11.706 5.3 0 10.729.882 16.13 2.627 20.198 6.512 34.606 28.456 34.742 28.673l.583.932-11.478 5.398-.46-.73c-1.236-2.106-12.119-20.054-27.515-25.02-3.781-1.22-7.765-1.837-11.837-1.837-7.376 0-13.357 2.01-16.54 3.36 5.319 2.395 11.77 6.426 15.85 12.863 2.22 3.506 2.58 7.791 2.932 11.932.432 5.148.84 9.99 4.452 12.988l1.363 1.13-12.638 4.143z"
      fill="#463014"
    />
    <path
      d="M61.334 58.544c-.005-.021-2.61-11.405-9.832-17.152-10.188-8.104-22.627-3.793-22.747-3.755l-1.806-5.58c.602-.195 14.82-4.675 27.923 4.536 13.182 9.263 14.764 17.66 14.901 18.587l-8.44 3.364z"
      fill="#463014"
    />
    <path
      d="M60.633 59.875l-.254-1.12c-.026-.11-2.581-11.104-9.488-16.596-3.937-3.134-8.684-4.726-14.102-4.726-4.229 0-7.372 1.01-7.715 1.129l-.933.31-2.423-7.443.929-.3c.416-.133 4.19-1.31 9.528-1.31 7.042 0 13.7 2.067 19.262 5.973C68.95 45.287 70.6 54.068 70.74 55.037l.119.766-10.226 4.072zM36.79 35.482c5.788 0 11.09 1.78 15.32 5.144 6.133 4.885 9.018 13.508 9.89 16.6l6.648-2.65c-.602-2.347-3.252-9.391-14.336-17.183-5.303-3.727-11.403-5.615-18.136-5.615-3.695 0-6.565.588-7.983.946l1.199 3.706c1.39-.374 4.066-.948 7.398-.948zM96.988 302.574c-2.888 0-5.835-1.048-7.877-2.816-1.564-1.348-6.852-6.205-9.184-12.155-.87-2.225-.675-4.005.588-5.286.87-.878 2.677-1.927 6.344-1.927l2.609-.004c3.109-.004 8.343-.004 12.817-.004h4.29c2.79 0 4.79.604 5.939 1.78 1.477 1.517 1.004 3.447.59 4.463-.718 1.78-3.693 7.571-8.729 12.955-1.803 1.93-4.427 2.994-7.387 2.994zM100.015 151.103l-10.426-.67s2.665 4.698 4.392 10.774c1.95 6.873-1.783 16.918-1.783 16.918s13.902-15.74 7.817-27.022zM87 330.104c-37.109-2.852-50.165-37.917-50.165-45.22l9.691.004-4.845-.004h4.845c.049 3.157 9.91 33.152 41.216 35.56l-.742 9.66z"
      fill="#463014"
    />
    <path
      d="M76.572 267.28c-.58-3.351-.64-6.53.067-9.653-2.82-2.12-7.88-2.907-11.677-4.138-3.105-1.965-18.994 2.014-30.52-11.936-14.443-17.468-11.404-27.897-11.404-27.897-3.703 4.114-7.292 2.546-12.936 21.73-5.653 19.188 2.653 32.564 18.133 45.993 7.313 6.343 17.651 8.957 26.544 9.957-2.979-2.99 5.088-3.29 10.648-5.37-.443-4.49.3-8.848 3.647-12.357 2.018-2.116 4.615-3.38 7.31-4.388.15-.348.277-.67.42-1.004a8.578 8.578 0 01-.232-.937z"
      fill="#FFF"
    />
    <path
      d="M65.353 285.849c-.322-.943-.565-1.903-.803-2.87-.257-1.055-1.537-4.047.04-.631-.405-.875-.67-1.776-.865-2.684-25.07-.032-46.88-17.245-42.666-53.432-3.282-4.357-7.878 8.786-7.878 8.786S.63 271.46 33.044 285.584c10.8 4.71 20.345 8.087 22.597 6.724 4.691.953 8.925-2.317 10.513-4.468-.297-.66-.569-1.322-.801-1.991z"
      fill="#C9C9C9"
    />
    <path
      d="M76.12 262.033c.039-3.054.663-6.013 2.14-8.96.103-.203.222-.398.333-.596-9.647-.083-20.757-1.006-30.218-4.793-10.399-4.16-23.407-18.39-25.642-35.11 0 0-19.584 15.112-13.83 47.459 5.402 30.373 30.819 40.275 59.806 41.95.016-.196-3.26-6.962-2.225-11.105-16.46 1.196-34.866-5.163-43.751-19.783-8.135-13.382-5.853-36.228-2.51-39.576 8.155 25.732 37.499 30.963 55.897 30.514z"
      fill="#463014"
    />
    <path
      d="M79.157 243.612c-5.216 15.307-15.87 27.696-15.87 27.696s14.656-.667 15.465-3.26c.599-1.912 13.234-31.49 18.084-66.14-4.562 7.495-17.232 40.393-17.679 41.704z"
      fill="#463014"
    />
    <path
      d="M162.194 208.32s16.615 25.41 28.809 43.443c1.886 2.79-12.194 2.983-12.194 2.983l-16.615-46.427zM154.28 155.637s5.01 12.498 7.75 19.123c2.98 7.203 14.336 10.786 14.336 10.786s-5.886-6.175-5.886-21.818c0-6.562-8.472-9.172-8.472-9.172l-7.728 1.08z"
      fill="#E78724"
    />
    <path
      d="M159.34 162.703s10.263 12.218 29.325 5.375c19.062-6.842 21.014-31.278 21.014-38.12 0-6.842-36.164 6.842-41.053 9.775-4.888 2.933-14.172 14.66-9.286 22.97zM231.328 32.153s-17.593-3.424-29.325 5.863c-11.727 9.285-15.15 16.617-15.15 16.617l19.77 15.67s9.556-4.43 12.488-17.137c2.931-12.706.977-18.57 12.217-21.013z"
      fill="#E78724"
    />
    <path
      d="M164.473 127.413c7.574-10.752 20.053-15.442 30.024-16.219 16.995-1.329 25.059 4.679 25.059 4.679s-27.832 1.178-35.856 11.714c-14.935 19.61-36.714 21.203-40.198 16.896 4.028.201 8.462-1.795 13.202-6.28 2.962-2.806 5.366-7.377 7.769-10.79zM211.457 165.431s30.877-10.616 25.012-21.365c-5.863-10.753-1.2-16.612-1.2-16.612s17.247 12.872 12.85 26.555c-2.701 8.398-7.231 14.479-32.107 26.137-11.932 22.163-2.464 62.055-3.028 62.242-.946.314-16.752-7.813-15.673-45.135.328-11.46 1.245-27.106 14.146-31.822zM170.992 51.276c12.944 6.578 30.686 15.739 30.686 15.739l-6.98 3.493c.666 1.079.988 1.723.988 1.723l-7.166 3.258s-17.494 12.672-28.212 20.82c3.57-11.005 14.06-28.292 14.06-28.292s2.978-7.943-3.376-16.741zM127.977 319.42c39.793 0 53.274-20.204 53.274-20.204l-6.216 15.146c-2.35 6.552-27.433 26.942-46.997 26.942"
      fill="#E78724"
    />
    <path
      d="M176.894 285.564s-3.666 22.378-18.324 22.378c-12.456 0-16.129-18.903-16.129-22.81l34.453.432zM201.293 251.814c-1.303-3.586-5.864-18.084-7.333-31.278-1.756-15.803-1.112-38.915-1.112-38.915s3.91 15.15 7.328 17.598c3.424 2.442 9.775 19.057 9.775 19.057s3.403 24.736 5.023 33.05c-4.482.897-8.252-1.744-13.681.488zM236.702 153.766s-8.31-2.935-10.752-21.015c-2.443-18.083 2.933-33.722 2.933-33.722s-.491 24.924 2.443 29.813c2.934 4.887 5.376 6.352 8.798 12.707 3.418 6.354-1.469 10.261-3.422 12.217z"
      fill="#E78724"
    />
    <path
      d="M226.78 36.43l1.699-1.074c5.001.169-1.699 1.074-1.699 1.074z"
      fill="#FDC168"
    />
    <path
      d="M164.227 45.922s7.821-1.302 11.729-7.166c3.91-5.865 14.99-17.596 32.648-17.727 17.656-.132 35.124 4.693 35.124 4.693l-15.249 9.635c-1.613-.053-4.433-.031-9.1.125-19.134.636-22.08 19.564-22.08 19.564l-67.44-9.287"
      fill="#FBA919"
    />
    <path
      d="M157.712 150.469s9.124 9.123 23.133 6.189c14.012-2.931 22.484-9.122 23.136-19.875.65-10.752-2.932-14.987-2.932-14.987s-15.642 4.89-23.135 11.407c-7.494 6.517-13.032 16.941-20.202 17.266z"
      fill="#FFF"
    />
    <path
      d="M168.751 147.21s7.916 3.614 16.757.67c11.58-3.862 12.351-15.902 12.351-15.902s7.099 20.843-12.814 24.855c-15.968 3.217-25.416-6.038-25.416-6.038l9.122-3.586z"
      fill="#C9C9C9"
    />
    <path
      d="M220.476 68.728c6.455-5.355 1.648-10.324 2.158-16.408.272-3.237.528-6.291 1.596-7.976 5.94-9.378 19.951-12.224 20.095-12.254l6.578-1.288-4.355-5.092c-.564-.66-18.44-17.717-46.11-8.785-19.348 6.241-33.673 27.405-34.206 28.26l9.7 4.563c.117-.18 11.444-20.074 28.046-25.427 13.42-4.33 25.788-.21 31.083 2.49-5.629 2.248-12.995 6.343-17.432 13.348-2.093 3.309-2.444 7.474-2.784 11.508-.45 5.34-.874 10.381-4.805 13.646l10.436 3.415zM237.711 146.893s-8.127-10.084-10.536-29.704c-.946-7.729.732-22.972 3.42-28.836-.489 14.417 3.18 28.836 3.18 28.836s2.471 26.771 3.936 29.704z"
      fill="#463014"
    />
    <path
      d="M194.838 58.544c.004-.021 2.609-11.405 9.832-17.152 10.187-8.104 22.627-3.793 22.743-3.755l1.811-5.58c-.604-.195-14.82-4.675-27.927 4.536-13.182 9.263-14.76 17.66-14.901 18.587l8.442 3.364zM212.176 139.661s4.266-6.107 3.495-12.823c-.378-3.317-1.915-6.722-4.66-7.93-5.099-2.24-7.36 3.54-7.36 3.54s7.178 1.771 8.525 17.213z"
      fill="#463014"
    />
    <path
      d="M165.336 148.97c-.178.332-.27.519-.27.519s13.301 9.986 26.938-.148c7.304-5.43 6.614-18.859 4.374-24.554-5.813 1.318-10.744 3.693-14.869 6.467 5.211-4.65 9.772-8.073 9.756-8.152l5.926-1.676 11.508-3.945c.148.878 4.345 20.556-7.777 33.965-5.37 5.945-30.55 19.259-42.502-3.507l6.916 1.03z"
      fill="#463014"
    />
    <path
      d="M166.578 150.433c-7.491 11.402-26.388 4.886-26.062-7.168 0 0 12.626 6.97 23.785-5.864 6.514-7.492 27.394-28.151 55.702-21.286-19.549 4.3-45.433 22.16-53.425 34.318z"
      fill="#463014"
    />
    <path
      d="M222.65 182.613c-1.804 1.041-8.163 7.624-8.163 17.468-.004 4.937.488 29.737 2.935 44.888-3.676 2.388-6.236 2.896-9.896 5.33-1.832-17.547-3.15-42.979-2.533-53.246.699-11.703 6.963-19.557 12.773-22.906 1.82-1.047 3.722-2.076 5.641-3.111 9.953-5.378 20.246-10.942 20.246-20.441 0-7.432-2.892-9.756-5.95-12.22l-.326-.267c-2.03-1.64-4.331-3.5-5.688-6.553-3.714-8.348-4.323-29.615-.004-35.013 2.154-2.69 7.928-11.736 6.269-17.815-.636-2.32-2.098-4.04-4.476-5.258-2.518-1.29-5.913-1.943-10.104-1.943-8.217 0-17.342 2.503-22.043 3.993-16.813 5.329-37.1 20.693-42.92 25.275-.166.128-.262.203-.28.215l-3.724 2.588 2.327-3.894c.148-.243 14.884-24.479 41.238-33.365 5.128-1.729 15.2-4.63 25.1-4.63 5.894 0 10.89 1.03 14.854 3.057 4.828 2.469 8.094 6.408 9.454 11.388 3.328 12.207-7.512 25.798-7.882 26.233-1.263 2.256-1.798 18.643 1.117 25.201.402.906 1.503 1.795 2.9 2.92.436.353.92.72 1.441 1.112 4.12 3.109 10.345 7.806 10.345 18.633 0 14.814-15.014 22.846-27.076 29.304-1.981 1.065-3.873 2.073-5.576 3.057z"
      fill="#463014"
    />
    <path
      d="M204.739 66.985c-2.388-4.045-8.496-11.855-22.571-18.718-15.709-7.656-33.889-11.542-54.044-11.551v-.005h-.078v.002c-20.158.01-38.342 3.897-54.046 11.552-14.076 6.864-20.18 14.673-22.57 18.718l-1.026 1.357 12.054 2.683.382-.483.202-.442c.186-.797 1.802-5.098 15.24-11.651 14.367-7.007 31.124-10.56 49.8-10.566 18.68.006 35.43 3.559 49.795 10.566 13.44 6.553 15.061 10.854 15.245 11.65l.201.443.378.483 12.057-2.683-1.02-1.355z"
      fill="#463014"
    />
    <path
      d="M220.476 68.728c6.455-5.355 1.648-10.324 2.158-16.408.272-3.237.528-6.291 1.596-7.976 5.94-9.378 19.951-12.224 20.095-12.254l6.578-1.288-4.355-5.092c-.564-.66-18.44-17.717-46.11-8.785-19.348 6.241-33.673 27.405-34.206 28.26l9.7 4.563c.117-.18 11.444-20.074 28.046-25.427 13.42-4.33 25.788-.21 31.083 2.49-5.629 2.248-12.995 6.343-17.432 13.348-2.093 3.309-2.444 7.474-2.784 11.508-.45 5.34-.874 10.381-4.805 13.646l10.436 3.415z"
      fill="#463014"
    />
    <path
      d="M208.054 65.687l1.358-1.131c3.615-2.998 4.02-7.84 4.457-12.988.35-4.142.711-8.428 2.93-11.932 4.078-6.435 10.533-10.467 15.85-12.865-3.183-1.348-9.165-3.358-16.542-3.358-4.074 0-8.05.616-11.833 1.837-15.4 4.966-26.282 22.914-27.514 25.02l-.462.73-11.478-5.398.584-.932c.135-.217 14.54-22.16 34.738-28.673 5.401-1.745 10.83-2.626 16.134-2.626 19.439 0 30.918 11.589 31.016 11.706l5.445 6.365-8.215 1.61c-.136.028-13.803 2.887-19.462 11.819-.938 1.484-1.184 4.42-1.45 7.537-.155 1.854.234 3.66.613 5.406.821 3.786 1.67 7.702-3.119 11.67l-.416.346-12.634-4.143z"
      fill="#463014"
    />
    <path
      d="M194.838 58.544c.004-.021 2.609-11.405 9.832-17.152 10.187-8.104 22.627-3.793 22.743-3.755l1.811-5.58c-.604-.195-14.82-4.675-27.927 4.536-13.182 9.263-14.76 17.66-14.901 18.587l8.442 3.364z"
      fill="#463014"
    />
    <path
      d="M185.318 55.803l.114-.766c.143-.969 1.794-9.748 15.304-19.245 5.558-3.906 12.218-5.972 19.26-5.972 5.341 0 9.115 1.174 9.533 1.309l.927.3-2.421 7.443-.935-.31c-.35-.118-3.491-1.129-7.718-1.129-5.417 0-10.161 1.59-14.098 4.726-6.908 5.494-9.464 16.488-9.488 16.596l-.254 1.118-10.224-4.07zM226.78 36.43l1.203-3.706c-1.419-.36-4.292-.946-7.987-.946-6.732 0-12.834 1.89-18.134 5.615-11.088 7.794-13.738 14.836-14.339 17.183l6.653 2.65c.87-3.094 3.752-11.717 9.888-16.6 4.234-3.365 9.53-5.144 15.317-5.144 3.328 0 6.009.574 7.4.948zM151.8 299.58c-5.035-5.383-8.014-11.176-8.734-12.955-.412-1.014-.88-2.946.596-4.464 1.151-1.175 3.146-1.779 5.935-1.779h4.293c4.474 0 9.705 0 12.816.004l2.61.004c3.664 0 5.47 1.048 6.339 1.927 1.264 1.279 1.461 3.058.59 5.286-2.33 5.951-7.62 10.808-9.183 12.155-2.048 1.768-4.988 2.816-7.876 2.816-2.96 0-5.583-1.064-7.386-2.994zM156.158 151.103l10.42-.67s-2.661 4.698-4.388 10.774c-1.95 6.873 1.783 16.918 1.783 16.918s-13.903-15.74-7.815-27.022z"
      fill="#463014"
    />
    <path
      d="M128.087 113.88c-6.645 0-15.309-.182-21.81-4.854 0 0 8.512 14.921 21.799 14.921l.011-1.888.011 1.888c13.288 0 21.798-14.92 21.798-14.92-6.5 4.67-15.17 4.853-21.809 4.853z"
      fill="#E78724"
    />
    <path
      d="M146.383 113.808c-6.665 4.034-12.618 5.432-18.296 5.556-5.68-.124-11.63-1.522-18.296-5.556 0 0 9.707 11.845 18.247 11.936v.007c.015 0 .03-.004.048-.004.015 0 .03.004.048.004v-.007c8.538-.091 18.249-11.936 18.249-11.936zM169.172 330.104l-.743-9.662c31.298-2.407 41.16-32.402 41.211-35.56h9.692c.004 7.303-13.056 42.37-50.16 45.222z"
      fill="#463014"
    />
    <path
      d="M179.598 267.28c.583-3.351.64-6.53-.067-9.653 2.824-2.12 7.88-2.907 11.677-4.138 3.105-1.965 18.998 2.014 30.525-11.936 14.442-17.468 11.399-27.897 11.399-27.897 3.706 4.114 7.292 2.546 12.94 21.73 5.653 19.188-2.657 32.564-18.137 45.993-7.312 6.343-17.651 8.957-26.544 9.957 2.979-2.99-5.088-3.29-10.645-5.37.444-4.49-.302-8.848-3.649-12.357-2.014-2.116-4.615-3.38-7.307-4.388-.152-.348-.282-.67-.421-1.004.089-.305.173-.613.23-.937z"
      fill="#FFF"
    />
    <path
      d="M190.814 285.849c.326-.943.564-1.903.803-2.87.261-1.055 1.541-4.047-.039-.631.406-.875.67-1.776.866-2.684 25.07-.032 46.88-17.245 42.662-53.432 3.282-4.357 7.881 8.786 7.881 8.786s12.551 36.442-19.863 50.566c-10.8 4.71-20.343 8.087-22.596 6.724-4.695.953-8.93-2.317-10.514-4.468.296-.66.574-1.322.8-1.991z"
      fill="#C9C9C9"
    />
    <path
      d="M180.047 262.033c-.035-3.054-.663-6.013-2.135-8.96-.105-.203-.224-.398-.334-.596 9.646-.083 20.757-1.006 30.218-4.793 10.398-4.16 23.406-18.39 25.642-35.11 0 0 19.584 15.112 13.83 47.459-5.406 30.373-30.82 40.275-59.806 41.95-.016-.196 3.261-6.962 2.225-11.105 16.455 1.196 34.862-5.163 43.752-19.783 8.13-13.382 5.85-36.228 2.506-39.576-8.152 25.732-37.499 30.963-55.898 30.514z"
      fill="#463014"
    />
    <path
      d="M191.752 269.652c-3.463-3.744-9.83-8.206-24.511-8.206l-39.105-.024h-.096c-11.27.008-39.11.024-39.11.024-14.678 0-21.045 4.463-24.51 8.206-5.047 5.449-5.839 13.028-2.347 22.502 9.278 25.217 22.317 50.837 65.965 50.873v.004c.016 0 .03-.004.048-.004.015 0 .03.004.048.004v-.004c43.644-.038 56.683-25.656 65.964-50.873 3.492-9.474 2.698-17.053-2.346-22.502zm-6.795 20.734c-4.42 12.663-16.301 43.313-56.872 43.366-40.576-.053-52.452-30.702-56.873-43.366-2.446-7.026-2.26-12.225.572-15.454 1.935-2.208 6.379-4.843 16.507-4.843l10.581-.004c7.806-.007 17.607-.015 29.214-.02 11.603.005 21.409.013 29.213.02l10.577.004c10.131 0 14.574 2.634 16.51 4.843 2.829 3.232 3.017 8.428.57 15.454z"
      fill="#463014"
    />
    <path
      d="M177.012 243.612c5.216 15.307 15.87 27.696 15.87 27.696s-14.656-.667-15.466-3.26c-.595-1.912-13.23-31.49-18.08-66.14 4.558 7.495 17.228 40.393 17.676 41.704z"
      fill="#463014"
    />
  </svg>
)

export default Grunt
