import React from "react"

const Phonegap = props => (
  <svg
    id="phonegap_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 256 279"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>{".phonegap_svg__st0{fill:#0f5b7a}"}</style>
    <path
      className="phonegap_svg__st0"
      d="M242.6 40.7c-.8-21.5-19.7-26-19.7-26s-65.5-9.9-94.2-13.5c-28.7-3.6-26.9 24.2-26.9 24.2s6.5 202.8 7.2 227.9c.8 29.9 28.7 25.1 28.7 25.1l98.7-17.1c17.9-3.6 18.8-26 18.8-26S243.5 64.1 242.6 40.7c-.9-21.5.9 23.4 0 0zm-22.1 172.2l-73.4 7.6s-21.4 3.3-21.9-22.7c-.4-19.1-4.6-128.7-4.6-128.7s-1.5-20.1 20.5-18.4c22.5 1.7 76.2 5.8 76.2 5.8s14.4 3.4 15 19.8c.7 17.8 6.9 116.9 6.9 116.9s1.8 16.2-18.7 19.7zM40.8 183.4l-13.6 1s-13.7 1.9-14-13.1c-.2-11-1.6-73.6-1.6-73.6S10.7 86 24.8 86.9c3.6.2 8.6.6 14.2.9-.2-10.4-.4-19.5-.5-26.4-7.7-.6-15-1.2-20.2-1.8C-.3 57.6.9 73.2.9 73.2S3.1 188 3.6 202.1c.5 16.8 18.6 14.1 18.6 14.1l19.3-2.1c-.3-8.3-.5-18.9-.7-30.7zM94.7 79c-.1-1.5-.3-2.8-.7-4-5.9-.5-20.8-4.4-25.6.7-3 3.2-1.8 9.9-1.7 13.7 6.4.4 10.8.6 10.8.6s9.2 2 9.6 11.4c.4 10.3.4 68 .4 68s1 9.4-12 11.4l-6.9.5c0 2 .1 3.7.1 4.9.3 16.4 17.8 11.2 28.8 10.1.9-17.4-.7-35.4-1.1-52.9-.5-21.4-.7-42.9-1.7-64.4 0-1.5.6 13.1 0 0z"
    />
    <path
      className="phonegap_svg__st0"
      d="M100.4 203.1L80.6 205s-18.4 2.6-18.8-18.6c-.3-15.7-2.1-104.6-2.1-104.6s-1.3-16.4 17.6-15.1c4.9.3 11.5.9 18.8 1.5-.5-17.1-1-30.5-1.2-37.4-10-1-19.4-2-26.3-2.8-25-2.8-23.4 19.3-23.4 19.3s3 163 3.6 182.9c.7 23.8 25 20 25 20l28-3.6c-.3-9.2-.8-24.8-1.4-43.5zM140.6 57.6c-3.5-.3-7.8 0-10.3 2.7-2.7 2.9-2.8 6.9-2.6 10.6 9.9.9 17.1 1.5 17.1 1.5s12.4 2.8 13 16.2c.6 14.6 4.1 94 4.1 94s1.4 13.3-16.2 16.2l-13.3 1.3c1.8 20.8 28.7 12 42.5 10.6-1.4-31.4-5.8-128.3-6.8-151.1-10.5-.8-20.5-1.6-27.5-2-.6-.1 7 .4 0 0z"
    />
  </svg>
)

export default Phonegap
