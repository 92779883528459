import React from "react"

const Nuxt = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 191"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <path
      d="M77.891 183.615a9.437 9.437 0 01-.506-1.04 13.329 13.329 0 01-.906-7.517H15.993l89.888-158.184 29.51 51.875 8.557-15.141L118.224 8.29c-.72-1.306-5.065-8.29-12.556-8.29-3.385 0-8.237 1.466-12.182 8.397L2.425 168.5c-.773 1.386-4.532 8.664-.8 15.115 1.706 2.932 5.332 6.424 13.329 6.424H91.22c-7.944 0-11.623-3.439-13.329-6.424z"
      fill="#01C58E"
    />
    <path
      d="M253.242 168.5L178.416 36.68c-.773-1.386-5.092-8.397-12.61-8.397-3.385 0-8.236 1.44-12.182 8.37l-9.676 16.955 8.61 15.14 13.329-23.59 74.027 129.9h-28.15a12.61 12.61 0 01-.587 6.398 8.157 8.157 0 01-.586 1.306l-.24.48c-3.785 6.45-11.97 6.797-13.568 6.797h44.037c1.626 0 9.783-.346 13.569-6.797 1.68-2.933 2.879-7.864-1.147-14.742z"
      fill="#108775"
    />
    <path
      d="M210.351 183.242l.24-.48a8.17 8.17 0 00.586-1.306c.745-2.047.947-4.25.587-6.398a19.486 19.486 0 00-2.453-6.558l-56.7-99.751-8.663-15.141-8.61 15.14L78.664 168.5a20.18 20.18 0 00-2.185 6.558 13.329 13.329 0 00.826 7.517c.147.357.316.704.506 1.04 1.706 2.932 5.332 6.424 13.329 6.424h105.563c1.679 0 9.863-.346 13.648-6.797zM143.948 83.89l51.822 91.168H92.153l51.795-91.168z"
      fill="#2F495E"
    />
  </svg>
)

export default Nuxt
