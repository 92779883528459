import React from "react"

const Jamstack = props => (
  <svg viewBox="0 0 512 85" width="1em" height="1em" {...props}>
    <path
      d="M32.8 1.9h16.3v56.7c0 8-2.3 14.3-6.9 19s-10.4 7-17.5 7c-7.6 0-13.6-2.1-18-6.3S.1 68.1.3 59.9l.2-.3h15.9c0 4.3.8 7.4 2.3 9.4 1.6 2 3.6 3 6 3 2.2 0 4.1-1.2 5.7-3.5s2.4-5.6 2.4-9.8V1.9zm61.5 64.3h-22l-4.5 17.3H51.3L74.9 1.9h17l23.5 81.6H98.9l-4.6-17.3zM75.6 53.4H91l-7.5-28.9h-.3l-7.6 28.9zm63.5-51.5l15 59.9h.3l15.2-59.9H191v81.6h-16.3V59.8l1.4-31.7-.3-.1-16 55.5h-10.9l-15.8-55-.3.1 1.4 31.3v23.7h-16.3V1.9h21.2zM255 64c0-5.1-1.9-9.2-5.8-12.3-3.9-3.1-10.5-5.9-20-8.4s-16.5-5.4-21-8.5c-4.4-3.2-6.6-7.9-6.6-14s2.6-10.9 7.8-14.6c5.2-3.6 11.9-5.4 20-5.4 8.2 0 14.8 2.2 20 6.7 5.2 4.4 7.7 10.2 7.5 17.1l-.1.3h-2.6c0-6.1-2.3-11.1-6.9-15.1s-10.6-6-17.9-6c-7.6 0-13.6 1.7-18 5s-6.6 7.3-6.6 11.9 1.8 8.5 5.5 11.5c3.7 3.1 10.3 5.8 19.9 8.2 9.3 2.4 16.3 5.3 21 8.8s7.1 8.4 7.1 14.7c0 6.5-2.7 11.6-8.1 15.3-5.4 3.7-12.2 5.6-20.3 5.6-8.4 0-15.6-1.9-21.7-5.7-6.1-3.8-9-9.9-8.8-18.1l.1-.3h2.6c0 7.4 2.8 12.8 8.4 16.1 5.6 3.3 12.1 5 19.4 5 7.4 0 13.4-1.6 18.1-4.9 4.7-3.4 7-7.7 7-12.9zm63.3-50.7c0 .4-.2.8-.5 1.1-.3.3-.7.5-1.1.5h-24.3v67c0 .4-.2.8-.5 1.1s-.7.5-1.2.5c-.4 0-.8-.1-1.2-.4-.3-.3-.5-.6-.5-1.1V15h-24.4c-.4 0-.8-.2-1.1-.5-.3-.3-.5-.7-.5-1.1s.1-.8.4-1.2c.3-.4.7-.6 1.2-.6h52c.5 0 .9.2 1.2.6.3.3.5.7.5 1.1zm47.5 45.8h-40.9l-9.3 24.4h-3.4l31.3-81.6h3.8l31.3 81.6h-3.4l-9.4-24.4zm-39.8-3h38.6l-17.5-45.9-1.6-4.6h-.3l-1.6 4.6L326 56.1zm113.8 2.4l.1.3c.2 8.4-2.4 14.8-7.8 19.2-5.3 4.4-12.4 6.6-21.1 6.6-9.2 0-16.5-3.3-22-9.9-5.5-6.6-8.3-15.2-8.3-26V36.6c0-10.7 2.8-19.3 8.3-25.9 5.5-6.6 12.9-9.9 22-9.9 8.7 0 15.8 2.2 21.1 6.7 5.3 4.4 7.9 10.8 7.8 19l-.1.3h-2.6c0-7.4-2.3-13-7-17s-11.1-6-19.1-6c-8.2 0-14.7 3-19.7 9s-7.5 13.9-7.5 23.7v12.3c0 9.9 2.5 17.8 7.5 23.8s11.6 9 19.7 9 14.4-1.9 19.1-5.8 7-9.6 7-17.4h2.6zm28.9-14.8h-12.8v39.8h-3.1V1.9h3.1v38.8h13.7l35.5-38.8h3.7l.1.3-36.7 40.4 39.4 40.6-.2.3h-3.6l-39.1-39.8z"
      fill="#4a4a4a"
    />
    <linearGradient
      id="jamstack_svg__a"
      gradientUnits="userSpaceOnUse"
      x1={-689.642}
      y1={420.64}
      x2={-689.639}
      y2={419.64}
      gradientTransform="matrix(54.6663 0 0 -3.0611 37990.465 1289.513)"
    >
      <stop offset={0} stopColor="#20c6b7" />
      <stop offset={1} stopColor="#4d9abf" />
    </linearGradient>
    <path
      d="M317.7 3.4c0 .4-.2.8-.5 1.1-.3.3-.7.5-1.1.5h-51.5c-.4 0-.8-.2-1.1-.5-.3-.3-.5-.7-.5-1.1s.2-.8.5-1.1.7-.5 1.1-.5h51.5c.4 0 .8.2 1.1.5s.5.7.5 1.1z"
      fill="url(#jamstack_svg__a)"
    />
  </svg>
)

export default Jamstack
