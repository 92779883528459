import React from "react"

const Middleman = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <rect fill="#F1C761" width={256} height={256} rx={22} />
    <g fillOpacity={0.35}>
      <path d="M118.097 189.197V68.218L88.676 72.35l-22.499 43.601-21.381-38.41-24.732 3.909v95.353l23.28 3.964v-60.35l22.777 42.931h.503L90.63 120.25v64.871l27.467 4.076M19.561 53.983v12.505l108.864-18.033V32.49L19.561 53.983M19.561 201.87l108.864 21.438v-15.911L19.561 189.308v12.562" />
    </g>
    <g fill="#FFF">
      <path d="M128.425 32.489v15.966L237.29 66.488V53.983L128.425 32.489M236.786 176.803V81.45l-24.731-3.908-21.382 38.409-22.499-43.6-29.42-4.133v120.979l27.467-4.076v-64.87l24.006 43.097h.502l22.778-42.93v60.349l23.28-3.964M128.425 223.308L237.29 201.87v-12.562l-108.864 18.089v15.91" />
    </g>
  </svg>
)

export default Middleman
