import React from "react"
import PropTypes from "prop-types"

const Github = props => (
  <svg viewBox="0 0 256 250" width="1em" height="1em" {...props}>
    <path
      fill={props.light ? "#161614" : "#FFFFFF"}
      d="M128 0C57.3 0 0 57.3 0 128c0 56.6 36.7 104.5 87.5 121.5 6.4 1.2 8.7-2.8 8.7-6.2 0-3.1-.1-13.1-.2-23.8-35.5 7.7-43-15.1-43-15.1-5.8-14.8-14.2-18.7-14.2-18.7-11.6-7.9.9-7.8.9-7.8 12.9.9 19.6 13.2 19.6 13.2 11.4 19.6 29.9 13.9 37.2 10.6 1.1-8.3 4.5-13.9 8.1-17.1-28.4-3.2-58.3-14.2-58.3-63.3 0-14 5-25.4 13.2-34.4-1.3-3.2-5.7-16.2 1.2-33.9 0 0 10.7-3.4 35.2 13.1 10.2-2.8 21.2-4.3 32-4.3 10.9 0 21.8 1.5 32.1 4.3C184.4 49.5 195.2 53 195.2 53c7 17.6 2.6 30.7 1.3 33.9 8.2 9 13.2 20.4 13.2 34.4 0 49.2-29.9 60-58.4 63.2 4.6 4 8.7 11.8 8.7 23.7 0 17.1-.1 30.9-.1 35.1 0 3.4 2.3 7.4 8.8 6.1C219.4 232.5 256 184.5 256 128 256 57.3 198.7 0 128 0zM47.9 182.3c-.3.6-1.3.8-2.2.4-.9-.4-1.4-1.3-1.1-1.9.3-.7 1.3-.8 2.2-.4.9.4 1.5 1.3 1.1 1.9zm6.3 5.7c-.6.6-1.8.3-2.6-.6-.8-.9-1-2.1-.4-2.7.6-.6 1.8-.3 2.6.6.9.9 1.1 2.1.4 2.7zm4.4 7.1c-.8.5-2.1 0-2.9-1.1-.8-1.1-.8-2.5 0-3.1.8-.5 2.1-.1 2.9 1.1.8 1.2.8 2.6 0 3.1zm7.3 8.4c-.7.8-2.2.6-3.3-.5-1.1-1-1.4-2.5-.7-3.3s2.2-.6 3.3.5c1.1 1 1.4 2.5.7 3.3zm9.4 2.8c-.3 1-1.7 1.5-3.2 1-1.4-.4-2.4-1.6-2.1-2.6s1.7-1.5 3.2-1c1.5.4 2.4 1.6 2.1 2.6zm10.7 1.2c0 1.1-1.2 1.9-2.7 2-1.5 0-2.8-.8-2.8-1.9 0-1.1 1.2-1.9 2.7-2 1.6 0 2.8.8 2.8 1.9zm10.6-.4c.2 1-.9 2.1-2.4 2.4-1.5.3-2.9-.4-3.1-1.4-.2-1.1.9-2.1 2.4-2.4 1.6-.3 2.9.3 3.1 1.4z"
    />
  </svg>
)

Github.propTypes = {
  light: PropTypes.number,
}

Github.defaultProps = {
  light: 0,
}

export default Github
